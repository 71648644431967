/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const memberships = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section
                className="whywelist page-section bg-secondary text-white"
                id="openpbasic"
                style={{ marginTop: "0px" }}
            >
                <div className="container smallestwdt">
                 {/*   <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.memberships_membership_title,
                        }}
                    />  */}
                    <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.memberships_membership_description,
                        }}
                    />

                    {post.acf.memberships_membership_list.map((membership, i) => (
                        <h5 key={`kt_${i}`}>
                            <span
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.memberships_membership_name,
                                }}
                            />
                            {' : '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.memberships_membership_price,
                                }}
                            />
                        </h5>
                    ))}
                    {/* <p>
                    Call for additional pricing for families with more than 3 children.
                    </p> */}
                </div>
            </section>

            {/* Table Section */}
            <section className="membership-table-section" style={{ marginTop: "80px" }}>
                <div className="main-flex_members" style={{ maxWidth: "1240px", margin: "auto", display: "flex", flexDirection: "column" }}>
                    <div className="single-row_members">
                        <div className="child_members yell_floo">Open Play</div>
                        <div className="child_members yell_floo">Monthly</div>
                        <div className="child_members yell_floo">6-Month</div>
                        <div className="child_members yell_floo">Yearly</div>
                    </div>
                    <div className="single-row_members">
                        <div className="child_members">2/hr Pass: $19.98/kid<br />Full Day Pass: $24.98/kid<br />$1 off for sibling(s)</div>
                        <div className="child_members">Starting at $99.98 per month</div>
                        <div className="child_members">Starting at $83.33 per month</div>
                        <div className="child_members">Starting at $66.66 per month</div>
                    </div>
                    <div className="single-row_members">
                        <div className="child_members">-</div>
                        <div className="child_members">Exclusive member events and deals</div>
                        <div className="child_members">Exclusive member events and deals</div>
                        <div className="child_members">Exclusive member events and deals</div>
                    </div>
                    <div className="single-row_members">
                        <div className="child_members">-</div>
                        <div className="child_members">Member Exclusive class event and camp registration</div>
                        <div className="child_members">Member Exclusive class event and camp registration</div>
                        <div className="child_members">Member Exclusive class event and camp registration</div>
                    </div>
                    <div className="single-row_members">
                        <div className="child_members">-</div>
                        <div className="child_members">10% Retail discounts</div>
                        <div className="child_members">10% Retail discounts</div>
                        <div className="child_members">10% Retail discounts</div>
                    </div>
                    <div className="single-row_members">
                        <div className="child_members">-</div>
                        <div className="child_members" style={{ background: "#faed00", flexDirection: "column" }}>
                            <span style={{ color: "#000" }}>Pays for itself in</span>
                            <span style={{ color: "red" }}> 4 visits</span>
                        </div>
                        <div className="child_members" style={{ background: "#faed00", flexDirection: "column" }}>
                            <span style={{ color: "#000" }}>Pays for itself after</span>
                            <span style={{ color: "red" }}> 3 visits</span>
                        </div>
                        <div className="child_members" style={{ background: "#faed00", flexDirection: "column" }}>
                            <span style={{ color: "#000" }}>Pays for itself after</span>
                            <span style={{ color: "red" }}> 2 visits</span>
                        </div>
                    </div>
                    <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px" }}>
                        Pricing subject to change starting Jan 1st, 2025
                    </p>
                </div>
            </section>



            <section className="page-section openphours" style={{ paddingTop: "20px" }}>
                <div className="container smallestwdt">

                    <div className="onethirdcol" style={{ width: "40%", float: "none", margin: "0 auto" }}>
                        <a
                            href="/register"
                            style={{ background: 'none', padding: 0 }}
                        >
                            <h6
                                className="greentxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.memberships_registration_title,
                                }}
                            />
                        </a>
                        {post.acf.memberships_register_widget_codes.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.memberships_register_widget_code,
                                    }}
                                />
                            )
                        )}

                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default memberships;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                memberships_membership_description
                memberships_membership_list {
                    memberships_membership_name
                    memberships_membership_price
                }
                memberships_membership_title
                memberships_registration_title
                memberships_register_widget_codes {
                    memberships_register_widget_code
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
